<template>
  <header>
    <div class="header">
      <div class="toolbar-header mb-5">
        <div class="left-content">
          <v-icon
            large
            color="primary"
            @click="theMenuButtonClicked">
            mdi-menu
          </v-icon>
          <h2 class="menu-text">
            <a :href="urlChorus+'/sm/'">
              {{ $t('toolbarTitle.ikusi') }}
            </a>
          </h2>
          </div>
          <div class="right-content">
            <v-chip
              v-if="isCookieTriaxBypass"
              label
              text-blond
              color="red"
              class="blink"
              @click="byPassLogOut"
            >
              {{ myAccount.sub }}
              <v-icon
                dark
                right
              >
                mdi-account-off
              </v-icon>
            </v-chip>
            <div class="chorus-logo">
              <a :href="urlChorus">
                <img src="@/assets/chorus/logo_chorus_text_small.png" alt="Chorus"/>
              </a>
            </div>
          </div>
      </div>
    </div>
  </header>

</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'ChorusNav',
  components: {
  },
  props: {
  },
  data () {
    return {
      urlChorus: location.protocol + '//' + window.location.host,
      isCookieTriaxBypass: false
    }
  },
  computed: {
    ...mapState({ myAccount: state => state.authentication.account })
  },
  created: function () {
    if (this.getCookie('triaxBypass')) {
      this.isCookieTriaxBypass = true
    }
  },
  methods: {
    getCookie: function (nombre) {
      const nombreBuscado = nombre + '='
      const cookies = document.cookie.split(';')

      for (let i = 0; i < cookies.length; i++) {
        const cookie = cookies[i].trim()
        if (cookie.indexOf(nombreBuscado) === 0) {
          return cookie.substring(nombreBuscado.length, cookie.length)
        }
      }
      return null
    },
    byPassLogOut: function () {
      // remove cooki
      document.cookie = 'triaxBypass=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; Max-Age=0'

      const accessToken = localStorage.getItem('bk_access_token')
      localStorage.setItem('access_token', accessToken)
      localStorage.removeItem('bk_access_token')

      const expires = localStorage.getItem('bk_expires')
      localStorage.setItem('expires', expires)
      localStorage.removeItem('bk_expires')

      const refreshToken = localStorage.getItem('refresh_token')
      localStorage.setItem('refresh_token', refreshToken)
      localStorage.removeItem('bk_refresh_token')

      const scope = localStorage.getItem('bk_scope')
      localStorage.setItem('scope', scope)
      localStorage.removeItem('bk_scope')

      window.location.href = 'https://' + window.location.host + '/#/byPassManager'
    },
    theMenuButtonClicked: function () {
      this.$emit('clickOnTheMenuButton')
    }
  }
}
</script>
<style scoped>

@import url('./header.css');
.menu-text a{
  margin-left: 0.5em;
  color: #274d71 !important;
}
.blink {
  margin-right: 1.5em;
  font-weight: bold;
  font-size: large;
  color: #FFFFFF !important;
  animation: blink-animation 1s steps(5, start) infinite;
  -webkit-animation: blink-animation 5s steps(5, start) infinite;
}
@keyframes blink-animation {
  to {
    visibility: hidden;
  }
}
@-webkit-keyframes blink-animation {
  to {
    visibility: hidden;
  }
}
</style>
