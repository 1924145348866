<template>
  <div>
    <ConfirmDialog
      v-model="confirmDialog.show"
      :item-id="confirmDialog.itemId"
      :title="confirmDialog.title"
      :message="confirmDialog.message"
      @confirmed="dialogConfirmed"
    />
    <v-btn
      v-if="showing.connect"
      class="ma-2"
      :disabled="isOffline"
      color="primary"
      :title="$t('headend.actions.access')"
      @click="accessHeadend"
    >
      <v-icon>mdi-login-variant</v-icon>
    </v-btn>
    <v-btn
      v-if="showing.report"
      class="ma-2"
      :disabled="isOffline"
      color="primary"
      :title="$t('headend.actions.downloadDetailedReport')"
      @click="downloadDetailedReport"
    >
      <v-icon>mdi-download</v-icon>
    </v-btn>
    <v-btn
      v-if="showing.reboot"
      class="ma-2"
      :disabled="disabled.reboot || isOffline"
      color="primary"
      :title="$t('headend.actions.reboot')"
      @click="confirmRebootHeadend"
    >
      <v-icon>mdi-restart</v-icon>
    </v-btn>
    <v-btn
      v-if="showing.camReboot"
      class="ma-2"
      :disabled="disabled.camReboot || isOffline || !isTSR"
      color="primary"
      :title="$t('headend.actions.camReboot')"
      @click="confirmCamHeadendReboot"
    >
      <v-icon>mdi-credit-card-refresh</v-icon>
    </v-btn>
    <v-btn
      v-if="showing.delete"
      class="ma-2"
      :disabled="!isOffline"
      color="primary"
      :title="$t('headend.actions.delete')"
      @click="confirmDeleteHeadend"
    >
      <v-icon>mdi-delete</v-icon>
    </v-btn>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import { NS_AUTHENTICATION, NS_LOADING, NS_SNACKBAR, NS_HEADEND } from '../../store/namespaces'
import { LOADING_INCREASE, LOADING_DECREASE } from '../../store/actions/loading'
import { SNACKBAR_SUCCESS, SNACKBAR_ERROR } from '../../store/actions/snackbar'
import { HEADEND_DELETE } from '../../store/actions/headend'
import ConfirmDialog from './ConfirmDialog.vue'
import DevicesService from '../../services/fleets/DevicesService'

export default {
  name: 'HeadendActions',
  components: {
    ConfirmDialog
  },
  props: {
    publicDomain: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      confirmDialog: {
        show: false
      },
      showing: {
        connect: false,
        report: false,
        delete: false,
        reboot: false,
        camReboot: false
      },
      disabled: {
        reboot: false
      }
    }
  },
  computed: {
    ...mapGetters(NS_AUTHENTICATION, ['hasRights', 'getUserRole']),
    ...mapGetters(NS_HEADEND, ['getHeadend']),
    headend: function () {
      return this.getHeadend
    },
    isOffline: function () {
      return this.headend.status.connection !== 'online'
    },
    isFlow: function () {
      return (
        this.headend.status.type === 'flowskyit' ||
        this.headend.status.type === 'flow'
      )
    },
    isTSR: function () {
      return (
        this.headend.status.type === 'tsr'
      )
    }
  },
  mounted () {
    if (this.hasRights('flt:a2m')) {
      this.showing.connect = true
    }
    if (this.hasRights('flt:mhe')) {
      this.showing.delete = true
    }
    if (this.hasRights('flt:dq')) {
      this.showing.report = true
    }
    if (this.hasRights('flt:da')) {
      this.showing.reboot = true
      this.showing.camReboot = true
    }
  },
  methods: {
    backToHeadends: function () {
      history.back()
    },
    accessHeadend: function () {
      const proto = '-http-'
      var url = this.$externalScheme +
          this.headend.id +
          proto +
          this.headend.pod +
          '.' +
          this.publicDomain
      if (this.$isTabcorp && this.headend.status.type === 'tsr') {
        if (this.getUserRole === 'installer') {
          url = url + '/index.iku?action=logout'
        }
      }
      window.open(
        url,
        '_blank'
      )
    },
    dialogConfirmed: function (itemId) {
      if (this.confirmDialog.type === 'delete') {
        this.removeHeadend(itemId)
      } else if (this.confirmDialog.type === 'reboot') {
        this.rebootHeadend(itemId)
      } else if (this.confirmDialog.type === 'camReboot') {
        this.camRebootHeadend(itemId)
      }
    },
    confirmDeleteHeadend: function () {
      this.confirmDialog.show = true
      this.confirmDialog.title = this.$t('headend.actions.confirmationDialog.deleteItemTitle')
      this.confirmDialog.message = this.$t('headend.actions.confirmationDialog.deleteItemMessage', { name: this.headend.status.remoteSiteDeviceName })
      this.confirmDialog.type = 'delete'
      this.confirmDialog.itemId = this.headend.id
    },
    removeHeadend: function (itemId) {
      this.$store.dispatch(NS_LOADING + '/' + LOADING_INCREASE)
      this.$store
        .dispatch(HEADEND_DELETE, itemId)
        .then(() => {
          this.$store.dispatch(NS_LOADING + '/' + LOADING_DECREASE)
          this.$store.dispatch(NS_SNACKBAR + '/' + SNACKBAR_SUCCESS, this.$t('success.headendDeleted'))
          this.backToHeadends()
        })
        .catch(() => {
          this.$store.dispatch(NS_LOADING + '/' + LOADING_DECREASE)
          this.$store.dispatch(NS_SNACKBAR + '/' + SNACKBAR_ERROR, this.$t('error.deletingHeadend'))
        })
    },
    confirmRebootHeadend: function () {
      this.confirmDialog.show = true
      this.confirmDialog.title = this.$t('headend.actions.confirmationDialog.rebootItemTitle')
      this.confirmDialog.message = this.$t('headend.actions.confirmationDialog.rebootItemMessage', { name: this.headend.status.remoteSiteDeviceName })
      this.confirmDialog.type = 'reboot'
      this.confirmDialog.itemId = this.headend.id
    },
    rebootHeadend: function (itemId) {
      this.disabled.reboot = true
      this.$store.dispatch(NS_LOADING + '/' + LOADING_INCREASE)
      DevicesService.rebootDevice(itemId)
        .then(() => {
          this.disabled.reboot = false
          this.$store.dispatch(NS_LOADING + '/' + LOADING_DECREASE)
          this.$store.dispatch(NS_SNACKBAR + '/' + SNACKBAR_SUCCESS, this.$t('success.headendRebooted'))
        })
        .catch(() => {
          this.disabled.reboot = false
          this.$store.dispatch(NS_LOADING + '/' + LOADING_DECREASE)
          this.$store.dispatch(NS_SNACKBAR + '/' + SNACKBAR_ERROR, this.$t('error.rebootingHeadend'))
        })
    },
    confirmCamHeadendReboot: function () {
      this.confirmDialog.show = true
      this.confirmDialog.title = this.$t('headend.actions.confirmationDialog.camRebootItemTitle')
      this.confirmDialog.message = this.$t('headend.actions.confirmationDialog.camRebootItemMessage', { name: this.headend.status.remoteSiteDeviceName })
      this.confirmDialog.type = 'camReboot'
      this.confirmDialog.itemId = this.headend.id
    },
    camRebootHeadend: function (itemId) {
      this.disabled.reboot = true
      this.$store.dispatch(NS_LOADING + '/' + LOADING_INCREASE)
      DevicesService.deviceCAMReboot(itemId).then(() => {
        this.disabled.reboot = false
        this.$store.dispatch(NS_LOADING + '/' + LOADING_DECREASE)
        this.$store.dispatch(NS_SNACKBAR + '/' + SNACKBAR_SUCCESS, this.$t('success.camRebooted'))
      }).catch(() => {
        this.disabled.reboot = false
        this.$store.dispatch(NS_LOADING + '/' + LOADING_DECREASE)
        this.$store.dispatch(NS_SNACKBAR + '/' + SNACKBAR_ERROR, this.$t('error.rebootingCam'))
      })
    },
    downloadDetailedReport: function () {
      this.$store.dispatch(NS_LOADING + '/' + LOADING_INCREASE)
      DevicesService.deviceReport(this.headend.id)
        .then(response => {
          this.$store.dispatch(NS_LOADING + '/' + LOADING_DECREASE)
          this.$store.dispatch(NS_SNACKBAR + '/' + SNACKBAR_SUCCESS, this.$t('success.downloadingDetailedReport'))
          var link = document.createElement('a')
          var file = new Blob([JSON.stringify(response.data, null, 4)], { type: 'text/plain' })
          link.href = URL.createObjectURL(file)
          const date = new Date()
          link.download = this.headend.id + '-' + date.toISOString() + '.json'
          link.click()
        })
        .catch(error => {
          this.$store.dispatch(NS_LOADING + '/' + LOADING_DECREASE)
          if (error.response.status !== 401) {
            this.$store.dispatch(NS_SNACKBAR + '/' + SNACKBAR_SUCCESS, this.$t('error.downloadingDetailedReport'))
          }
        })
    }
  }
}
</script>
<style></style>
