<template>
  <v-card :loading="loading">
    <v-card-title>
      {{ $t('headendDRM.title') }}
      <v-spacer />
      <v-btn
        :disabled="!isOnline || loading"
        text
        icon
        color="primary"
        @click="refresh"
      >
        <v-icon>mdi-refresh</v-icon>
      </v-btn>
    </v-card-title>
    <v-card-text>
      <v-container v-if="headend.status.type==='classa' || headend.status.type==='classaskyit'">
        <v-row>
          <v-col
            v-if="!headend.status.drm"
            cols="12"
            md="12"
          >
            <v-text-field
              v-model="noDRM"
              :label="$t('headendDRM.type')"
              readonly
            />
          </v-col>
          <v-col
            v-for="(item, i) in headend.status.drm"
            :key="i"
            cols="12"
            md="12"
          >
            <v-text-field
              v-model="mutableHeadend.status.drm[i]"
              :label="$t('headendDRM.type')"
              readonly
            >
              <template #append-outer>
                <v-btn
                  v-if="isLynk(item)"
                  :disabled="!isOnline"
                  color="primary"
                  :title="$t('headendDRM.accessLynk')"
                  @click="accessLynk"
                >
                  <v-icon>mdi-lock-open</v-icon>
                </v-btn>
              </template>
            </v-text-field>
          </v-col>
          <v-col v-if="!firstTime">
            <v-btn
              v-if="headend.status.drm.length === 0"
              :disabled="!isOnline"
              @click="activateDRM"
            >
              {{ $t('headendDRM.activateDRM') }}
            </v-btn>
            <v-btn
              v-if="headend.status.drm.length > 0"
              :disabled="!isOnline"
              @click="deactivateDRM"
            >
              {{ $t('headendDRM.deactivateDRM') }}
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
      <!-- ELSE -->
      <v-container v-else>
        <v-row>
          <v-col
            v-if="!headend.status.drm"
            cols="12"
            md="12"
          >
            <v-text-field
              v-model="noDRM"
              :label="$t('headendDRM.type')"
              readonly
            />
          </v-col>
          <v-col
            v-for="(item, i) in headend.status.drm"
            :key="i"
            cols="12"
            md="12"
          >
            <v-text-field
              v-model="mutableHeadend.status.drm[i]"
              :label="$t('headendDRM.type')"
              readonly
            >
              <template #append-outer>
                <v-btn
                  v-if="isLynk(item)"
                  :disabled="!isOnline"
                  color="primary"
                  :title="$t('headendDRM.accessLynk')"
                  @click="accessLynk"
                >
                  <v-icon>mdi-lock-open</v-icon>
                </v-btn>
              </template>
            </v-text-field>
          </v-col>
          <v-col v-if="!firstTime">
            <v-checkbox
              v-model="isBypassed"
              :label="$t('headendDRM.dRMBypassed')"
              :disabled="!isOnline"
              @click="changeBypass"
            />
            <p>{{ $t('headendDRM.ifDRMIsByPassedInfo') }}</p>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
  </v-card>
</template>

<script>
import { SNACKBAR_SUCCESS, SNACKBAR_ERROR } from '@/store/actions/snackbar'
import DeviceService from '@/services/fleets/DevicesService'

export default {
  name: 'HeadendDRM',
  components: {},
  props: {
    headend: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      noDRM: this.$t('headendDRM.noDRM'),
      loading: false,
      firstTime: true,
      isBypassed: undefined
    }
  },
  computed: {
    isOnline: function () {
      return this.headend.status.connection === 'online'
    },
    mutableHeadend: function () {
      return JSON.parse(JSON.stringify(this.headend))
    }
  },
  watch: {
    'headend.status.drm': function (newVal, oldVal) {
      if (JSON.stringify(newVal) !== JSON.stringify(oldVal)) {
        this.drmInfo()
      }
    }
  },
  mounted () {
    if (this.headend.status.connection === 'online') {
      this.drmInfo()
    }
  },
  methods: {
    isLynk: function (drmType) {
      return drmType === 'lynk'
    },
    accessLynk: function () {
      window.open(
        this.$externalScheme +
          this.headend.id +
          '-lynk.' +
          // process.env.VUE_APP_PUBLIC_DOMAIN,
          window.location.host,
        '_blank'
      )
    },
    drmInfo: function () {
      if (this.isOnline) {
        this.loading = true
        DeviceService
          .isDRMBypassed(this.headend.id)
          .then(response => {
            this.loading = false
            this.firstTime = false
            this.isBypassed = response.data.isBypassed
          })
          .catch(() => {
            this.loading = false
          })
      }
    },
    changeBypass: function () {
      this.loading = true
      DeviceService
        .putDRMBypass(this.headend.id, this.isBypassed)
        .then(data => {
          this.loading = false
          this.$store.dispatch(
            SNACKBAR_SUCCESS,
            this.$t('headendDRM.bypassChanged')
          )
        })
        .catch(() => {
          this.loading = false
          this.$store.dispatch(
            SNACKBAR_ERROR,
            this.$t('headendDRM.errorChangingDRMBypass')
          )
          this.isBypassed = !this.isBypassed
        })
    },
    activateDRM: function () {
      this.loading = true
      DeviceService
        .putHeadendDRM(this.headend.id, false)
        .then(data => {
          this.drmInfo()
          this.$store.dispatch(
            SNACKBAR_SUCCESS,
            this.$t('headendDRM.bypassChanged')
          )
        })
        .catch(() => {
          this.loading = false
          this.$store.dispatch(
            SNACKBAR_ERROR,
            this.$t('headendDRM.errorChangingDRMBypass')
          )
          this.isBypassed = !this.isBypassed
        })
    },
    deactivateDRM: function () {
      this.loading = true
      DeviceService
        .putHeadendDRM(this.headend.id, false)
        .then(data => {
          this.drmInfo()
          this.$store.dispatch(
            SNACKBAR_SUCCESS,
            this.$t('headendDRM.bypassChanged')
          )
        })
        .catch(() => {
          this.loading = false
          this.$store.dispatch(
            SNACKBAR_ERROR,
            this.$t('headendDRM.errorChangingDRMBypass')
          )
          this.isBypassed = !this.isBypassed
        })
    },
    refresh: function () {
      this.drmInfo()
    }
  }
}
</script>
