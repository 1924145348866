import {
  AUTH_LOAD,
  AUTH_REQUEST,
  AUTH_BYPASS,
  AUTH_LOGOUT,
  AUTH_REFRESH,
  AUTH_ERROR,
  AUTH_SUCCESS,
  AUTH_SUCCESS_BYPASS,
  AUTH_AUTO_REFRESH
} from '@/store/actions/authentication'
import {
  CUSTOMIZATION_LANGUAGE,
  CUSTOMIZATION_TIME_ZONE
} from '@/store/actions/customization'

import session from '@/services/api/authorization/Session'
import authorization from '@/services/api/authorization/Authorization'
import axios from 'axios'

export default {
  state: {
    status: '',
    token: localStorage.getItem('user-token') || '',
    scope: localStorage.getItem('scope') || [],
    account: {},
    refresh: { timesCalled: 0, timeoutId: undefined }
  },
  mutations: {
    [AUTH_LOAD]: state => {
      state.status = 'loading'
    },
    [AUTH_REQUEST]: state => {
      state.status = 'authenticating'
    },
    [AUTH_SUCCESS]: (state, resp) => {
      state.status = 'authenticated'
      state.token = resp.accessToken
      state.scope = resp.scope.split(' ')
      const token = atob(resp.access_token)
      const base64Payload = token.split('.')[1]
      const base64 = base64Payload.replace('-', '+').replace('_', '/')
      state.account = JSON.parse(atob(base64))
      state.refresh.timesCalled = 0
    },
    [AUTH_SUCCESS_BYPASS]: (state, resp) => {
      state.status = 'authenticated'
      state.token = resp.access_token
      state.scope = resp.scope.split(' ')
      const token = atob(resp.access_token)
      const base64Payload = token.split('.')[1]
      const base64 = base64Payload.replace('-', '+').replace('_', '/')
      state.account = JSON.parse(atob(base64))
      state.refresh.timesCalled = 0
    },
    [AUTH_ERROR]: state => {
      state.status = 'error'
    },
    [AUTH_LOGOUT]: state => {
      state.status = ''
      state.token = ''
      state.account = {
        clientId: '',
        clientName: '',
        exp: '',
        firstName: '',
        iat: '',
        iss: '',
        jti: '',
        language: '',
        role: '',
        scopes: '',
        secondName: '',
        sub: '',
        timeZone: 'UTC',
        userId: ''
      }
    },
    [AUTH_AUTO_REFRESH]: (state, refresh) => {
      state.refresh = refresh
    }
  },
  actions: {
    [AUTH_LOAD]: ({ commit, dispatch }) => {
      return new Promise((resolve, reject) => {
        commit(AUTH_LOAD)
        const accessToken = localStorage.getItem('access_token')
        const expires = localStorage.getItem('expires')
        if (
          accessToken === null ||
          accessToken === undefined ||
          expires === null ||
          expires === undefined
        ) {
          commit(AUTH_LOGOUT)
          reject(new Error('no-data'))
          return
        }
        if (new Date() > expires) {
          commit(AUTH_LOGOUT)
          reject(new Error('no-data'))
          return
        }
        dispatch(AUTH_REFRESH)
          .then(response => {
            // console.log('[store.auth] Trying to load from local data')
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    [AUTH_REQUEST]: ({ state, commit, dispatch }, login) => {
      return new Promise((resolve, reject) => {
        commit(AUTH_REQUEST)
        session
          .login(login.username, login.password)
          .then(response => {
            localStorage.setItem('access_token', response.data.access_token)
            localStorage.setItem('refresh_token', response.data.refresh_token)
            localStorage.setItem('scope', response.data.scope.split(' '))
            const expires = new Date(
              new Date().getTime() + response.data.expires_in * 1000
            )
            localStorage.setItem('expires', expires)
            axios.defaults.headers.common.Authorization =
              'Bearer ' + response.data.access_token
            commit(AUTH_SUCCESS, response.data)
            dispatch(AUTH_AUTO_REFRESH, 3600 - 60)
            dispatch(
              'customization/' + CUSTOMIZATION_LANGUAGE,
              state.account.language,
              { root: true }
            )
            dispatch(
              'customization/' + CUSTOMIZATION_TIME_ZONE,
              state.account.timeZone,
              { root: true }
            )
            resolve(response.data)
          })
          .catch(error => {
            commit(AUTH_ERROR, error)
            localStorage.removeItem('access_token')
            localStorage.removeItem('refresh_token')
            localStorage.removeItem('expires')
            reject(error)
          })
      })
    },
    [AUTH_BYPASS]: ({ state, commit, dispatch }, data) => {
      return new Promise((resolve) => {
        commit(AUTH_REQUEST)
        localStorage.setItem('access_token', data.access_token)
        localStorage.setItem('refresh_token', data.refresh_token)
        localStorage.setItem('scope', data.scope.split(' '))
        localStorage.setItem('expires', data.expires_in)
        axios.defaults.headers.common.Authorization =
          'Bearer ' + data.access_token
        commit(AUTH_SUCCESS_BYPASS, data)
        dispatch(AUTH_AUTO_REFRESH, data.expires_in - 60)
        dispatch(
          'customization/' + CUSTOMIZATION_LANGUAGE,
          state.account.language,
          { root: true }
        )
        dispatch(
          'customization/' + CUSTOMIZATION_TIME_ZONE,
          state.account.timeZone,
          { root: true }
        )
        resolve()
      })
    },
    [AUTH_LOGOUT]: ({ commit, dispatch }) => {
      return new Promise(resolve => {
        authorization.revoke(localStorage.getItem('refresh_token'))
        localStorage.removeItem('access_token')
        localStorage.removeItem('refresh_token')
        localStorage.removeItem('scope')
        localStorage.removeItem('expires')
        axios.defaults.headers.common.Authorization = ''
        commit(AUTH_LOGOUT)
        // console.log('[store.auth] Auth data cleared')
        resolve()
      })
    },
    [AUTH_REFRESH]: ({ commit, dispatch }) => {
      return new Promise((resolve, reject) => {
        // console.log('[store.auth] Refreshing token')
        const accessToken = localStorage.getItem('access_token')
        if (accessToken === null || accessToken === undefined) {
          return
        }
        axios.defaults.headers.common.Authorization = 'Bearer ' + accessToken
        authorization
          .refresh(localStorage.getItem('refresh_token'))
          .then(response => {
            localStorage.setItem('access_token', response.data.access_token)
            localStorage.setItem('scope', response.data.scope)
            const expires = new Date(
              new Date().getTime() + response.data.expires_in * 1000
            )
            localStorage.setItem('expires', expires)
            axios.defaults.headers.common.Authorization =
              'Bearer ' + response.data.access_token
            commit(AUTH_SUCCESS, response.data)
            resolve(response.data)
            dispatch(AUTH_AUTO_REFRESH, response.data.expires_in - 60)
            // console.log('[store.auth] Token refreshed')
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    [AUTH_AUTO_REFRESH]: (
      { state, commit, dispatch },
      timeToRefreshInSeconds
    ) => {
      clearTimeout(state.refresh.timeoutId)
      if (state.refresh.timesCalled <= 3) {
        const timeoutId = setTimeout(function () {
          // console.log('[store.auth] Refreshing token')
          dispatch(AUTH_REFRESH).catch(() => {
            commit(AUTH_AUTO_REFRESH, {
              timesCalled: state.refresh.timesCalled + 1,
              timeoutId: state.refresh.timeoutId
            })
            dispatch(AUTH_AUTO_REFRESH, 1)
          })
        }, timeToRefreshInSeconds * 1000)
        commit(AUTH_AUTO_REFRESH, {
          timesCalled: state.refresh.timesCalled,
          timeoutId: timeoutId
        })
      }
    }
  },
  getters: {
    isAuthenticated: state => state.status === 'authenticated',
    hasRights: state => (scope) => {
      return state.scope.includes(scope)
    },
    getAccountClientId: state => state.account.clientId,
    getAccountUserId: state => state.account.userId,
    getAccountUserRole: state => state.account.role
  }
}
