import {
  CUSTOMIZATION_PROVIDER,
  CUSTOMIZATION_LANGUAGE,
  CUSTOMIZATION_TIME_ZONE
} from '@/store/actions/customization'

import i18next from '@/plugins/i18next'
import vuetify from '@/plugins/vuetify'

export default {
  namespaced: true,
  state: {
    status: '',
    type: '',
    customization: {
      primaryColor: '#274d71',
      lang: 'es',
      langLocation: 'es-ES'
    },
    language: 'es-ES',
    locale: 'es-ES',
    timeZone: 'Europe/Madrid'
  },
  mutations: {
    [CUSTOMIZATION_PROVIDER]: (state, type) => {
      state.status = 'customized'
      state.type = type
      switch (type) {
        case 'skyit':
          state.customization.primaryColor = '#0073c5'
          break
        default:
      }
    },
    [CUSTOMIZATION_LANGUAGE]: (state, language) => {
      state.language = language
      const splitedLanguageAndLocale = language.split('-')
      state.location =
        splitedLanguageAndLocale[0] + '-' + splitedLanguageAndLocale[1]
    },
    [CUSTOMIZATION_TIME_ZONE]: (state, timeZone) => {
      state.timeZone = timeZone
    }
  },
  actions: {
    [CUSTOMIZATION_PROVIDER]: ({ commit, dispatch }, customType) => {
      let timeZone
      let defaultLanguage
      if (customType === 'skyit') {
        defaultLanguage = 'it-IT'
        timeZone = 'Europe/Rome'
      } else if (customType === 'tabcorp') {
        defaultLanguage = 'en-AU'
        timeZone = 'Australia/Sydney'
      } else {
        defaultLanguage = 'es-ES'
        timeZone = 'Europe/Madrid'
      }
      const localStorageLang = localStorage.getItem('language')
      if (localStorageLang === null || localStorageLang === undefined) {
        dispatch(CUSTOMIZATION_LANGUAGE, defaultLanguage)
      } else {
        dispatch(CUSTOMIZATION_LANGUAGE, localStorageLang)
      }

      dispatch(CUSTOMIZATION_TIME_ZONE, timeZone)
      commit(CUSTOMIZATION_PROVIDER, customType)
    },
    [CUSTOMIZATION_LANGUAGE]: ({ state, commit }, language) => {
      if (language === state.language) return
      const splitedLanguageAndLocale = language.split('-')
      i18next.changeLanguage(language)
      vuetify.framework.lang.current = splitedLanguageAndLocale[0]
      localStorage.setItem('language', language)
      commit(CUSTOMIZATION_LANGUAGE, language)
    },
    [CUSTOMIZATION_TIME_ZONE]: ({ state, commit }, timeZone) => {
      if (timeZone === state.timeZone) return
      commit(CUSTOMIZATION_TIME_ZONE, timeZone)
    }
  },
  getters: {
    isCustomized: state => !!state.status,
    customizationStatus: state => state.status,
    customizationType: state => state.type,
    isSky: state => state.type === 'skyit',
    isTabcorp: state => state.type === 'tabcorp',
    primaryColor: state => state.customization.primaryColor,
    timeZone: state => state.timeZone,
    language: state => state.language,
    locale: state => state.locale
  }
}
