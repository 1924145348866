<template>
  <div>
    <ConfirmDialog
      v-model="confirmDialog.show"
      :item-id="confirmDialog.itemId"
      :title="confirmDialog.title"
      :message="confirmDialog.message"
      @confirmed="dialogConfirmed"
    />
    <v-toolbar>
      <v-btn
        icon
        color="primary"
        :title="$t('back')"
        @click="backToHeadends"
      >
        <v-icon>mdi-arrow-left-circle</v-icon>
      </v-btn>
      <v-chip
        v-if="showing.drm && hasDRM()"
        class="ma-2"
        color="default"
        :title="$t('headendAction.noDRM')"
      >
        <del>DRM</del>
      </v-chip>
      <v-toolbar-title>
        {{ $t('headendView') }}
      </v-toolbar-title>
      <v-spacer />
      <v-btn
        v-if="showing.connect"
        class="ma-2"
        :disabled="isOffline"
        color="primary"
        :title="$t('headendAction.access')"
        @click="accessHeadend"
      >
        <v-icon>mdi-login-variant</v-icon>
      </v-btn>
      <v-btn
        v-if="showing.report"
        class="ma-2"
        :disabled="isOffline"
        color="primary"
        :title="$t('headendAction.downloadDetailedReport')"
        @click="downloadDetailedReport"
      >
        <v-icon>mdi-download</v-icon>
      </v-btn>
      <v-btn
        v-if="showing.switch"
        class="ma-2"
        :disabled="isOffline || !isFlow"
        color="primary"
        :title="$t('headendAction.accessSwitch')"
        @click="accessSwitch"
      >
        <v-icon>mdi-router-network</v-icon>
      </v-btn>
      <v-btn
        v-if="showing.reboot"
        class="ma-2"
        :disabled="disabled.reboot || isOffline"
        color="primary"
        :title="$t('headendAction.reboot')"
        @click="confirmRebootHeadend"
      >
        <v-icon>mdi-restart</v-icon>
      </v-btn>
      <v-btn
        v-if="showing.camReboot"
        class="ma-2"
        :disabled="disabled.camReboot || isOffline || !isTSR"
        color="primary"
        :title="$t('headendAction.camReboot')"
        @click="confirmCamHeadendReboot"
      >
        <v-icon>mdi-credit-card-refresh</v-icon>
      </v-btn>
      <v-btn
        v-if="showing.scan"
        class="ma-2"
        :disabled="disabled.scan || isOffline || !isFlow"
        color="primary"
        :title="$t('headendAction.scan')"
        @click="confirmScan"
      >
        <v-icon>mdi-text-search</v-icon>
      </v-btn>
      <v-btn
        v-if="showing.delete"
        class="ma-2"
        :disabled="!isOffline"
        color="primary"
        :title="$t('headendAction.delete')"
        @click="confirmDeleteHeadend"
      >
        <v-icon>mdi-delete</v-icon>
      </v-btn>
    </v-toolbar>
  </div>
</template>
<script>
import router from '@/router'
import { mapGetters } from 'vuex'
import { LOADING_INCREASE, LOADING_DECREASE } from '@/store/actions/loading'
import { SNACKBAR_SUCCESS, SNACKBAR_ERROR } from '@/store/actions/snackbar'
import { HEADENDS_DELETE } from '@/store/actions/headends'
import ConfirmDialog from '@/components/common/ConfirmDialog.vue'
import DevicesService from '@/services/fleets/DevicesService'

export default {
  name: 'HeadendActions',
  components: {
    ConfirmDialog
  },
  props: {
    headend: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      confirmDialog: {
        show: false
      },
      showing: {
        connect: false,
        report: false,
        switch: false,
        delete: false,
        drm: false,
        reboot: false,
        camReboot: false,
        scan: false
      },
      disabled: {
        reboot: false
      }
    }
  },
  computed: {
    ...mapGetters(['hasRights', 'getAccountUserRole']),
    isOffline: function () {
      return this.headend.status.connection !== 'online'
    },
    isFlow: function () {
      return (
        this.headend.status.type === 'flowskyit' ||
        this.headend.status.type === 'flow'
      )
    },
    isTSR: function () {
      return (
        this.headend.status.type === 'tsr'
      )
    }
  },
  mounted () {
    if (this.hasRights('flt:a2m')) {
      this.showing.connect = true
    }
    if (this.hasRights('flt:mhe')) {
      this.showing.delete = true
    }
    if (this.$isTabcorp) {
      if (this.hasRights('flt:dq')) {
        this.showing.report = true
      }
      if (this.hasRights('flt:da')) {
        this.showing.reboot = true
        this.showing.camReboot = true
      }
    } else if (this.$isSky) {
      if (this.hasRights('flt:da')) {
        this.showing.drm = true
        this.showing.reboot = true
        this.showing.scan = true
      }
    } else {
      if (this.hasRights('flt:dq')) {
        this.showing.report = true
      }
      if (this.hasRights('flt:da')) {
        this.showing.reboot = true
        this.showing.scan = true
        this.showing.camReboot = true
      }
      this.showing.switch = true
    }
  },
  methods: {
    backToHeadends: function () {
      router.push('/headends')
    },
    accessHeadend: function () {
      const proto = '-http-'
      var url = this.$externalScheme +
          this.headend.id +
          proto +
          this.headend.pod +
          '.' +
          window.location.host
          // process.env.VUE_APP_PUBLIC_DOMAIN
      if (this.$isTabcorp && this.headend.status.type === 'tsr') {
        if (this.getAccountUserRole === 'installer') {
          url = url + '/index.iku?action=logout'
        }
      }
      window.open(
        url,
        '_blank'
      )
    },
    accessSwitch: function () {
      const proto = 'switch'
      window.open(
        this.$externalScheme +
          this.headend.id +
          '-' +
          proto +
          '.' +
          process.env.VUE_APP_PUBLIC_DOMAIN,
        '_blank'
      )
    },
    hasDRM: function () {
      return this.headend.status.drm === true
    },
    dialogConfirmed: function (itemId) {
      if (this.confirmDialog.type === 'delete') {
        this.removeHeadend(itemId)
      } else if (this.confirmDialog.type === 'reboot') {
        this.rebootHeadend(itemId)
      } else if (this.confirmDialog.type === 'scan') {
        this.scanHeadend(itemId)
      } else if (this.confirmDialog.type === 'camReboot') {
        this.camRebootHeadend(itemId)
      }
    },
    confirmDeleteHeadend: function () {
      this.confirmDialog.show = true
      this.confirmDialog.title = this.$t('headends.deleteItemTitle')
      this.confirmDialog.message = this.$t(
        'headends.deleteItemMessage',
        this.headend
      )
      this.confirmDialog.type = 'delete'
      this.confirmDialog.itemId = this.headend.id
    },
    removeHeadend: function (itemId) {
      this.$store.dispatch(LOADING_INCREASE)
      this.$store
        .dispatch(HEADENDS_DELETE, itemId)
        .then(() => {
          this.$store.dispatch(LOADING_DECREASE)
          this.$store.dispatch(
            SNACKBAR_SUCCESS,
            this.$t('headends.headendDeleted')
          )
          this.backToHeadends()
        })
        .catch(() => {
          this.$store.dispatch(LOADING_DECREASE)
          this.$store.dispatch(
            SNACKBAR_ERROR,
            this.$t('headends.errorDeletingHeadend')
          )
        })
    },
    confirmScan: function () {
      this.confirmDialog.show = true
      this.confirmDialog.title = this.$t('headends.scanItemTitle')
      this.confirmDialog.message = this.$t(
        'headends.scanItemMessage',
        this.headend
      )
      this.confirmDialog.type = 'scan'
      this.confirmDialog.itemId = this.headend.id
    },
    scanHeadend: function (itemId) {
      this.disabled.reboot = true
      DevicesService.sendScanToDevice(itemId)
        .then(() => {
          this.disabled.reboot = false
          this.$store.dispatch(
            SNACKBAR_SUCCESS,
            this.$t('headendAction.scanning')
          )
        })
        .catch(() => {
          this.disabled.reboot = false
          this.$store.dispatch(
            SNACKBAR_ERROR,
            this.$t('headendAction.errorScanning')
          )
        })
    },
    confirmRebootHeadend: function () {
      this.confirmDialog.show = true
      this.confirmDialog.title = this.$t('headends.rebootItemTitle')
      this.confirmDialog.message = this.$t(
        'headends.rebootItemMessage',
        this.headend
      )
      this.confirmDialog.type = 'reboot'
      this.confirmDialog.itemId = this.headend.id
    },
    rebootHeadend: function (itemId) {
      this.disabled.reboot = true
      DevicesService.rebootDevice(itemId)
        .then(() => {
          this.disabled.reboot = false
          this.$store.dispatch(
            SNACKBAR_SUCCESS,
            this.$t('headendAction.rebooted')
          )
        })
        .catch(() => {
          this.disabled.reboot = false
          this.$store.dispatch(
            SNACKBAR_ERROR,
            this.$t('headendAction.errorRebooting')
          )
        })
    },
    confirmCamHeadendReboot: function () {
      this.confirmDialog.show = true
      this.confirmDialog.title = this.$t('headends.camRebootItemTitle')
      this.confirmDialog.message = this.$t(
        'headends.camRebootItemMessage',
        this.headend
      )
      this.confirmDialog.type = 'camReboot'
      this.confirmDialog.itemId = this.headend.id
    },
    camRebootHeadend: function (itemId) {
      this.disabled.reboot = true
      DevicesService.deviceCAMReboot(itemId).then(() => {
        this.disabled.reboot = false
        this.$store.dispatch(
          SNACKBAR_SUCCESS,
          this.$t('headends.camRebooted')
        )
      }).catch(() => {
        this.disabled.reboot = false
        this.$store.dispatch(
          SNACKBAR_ERROR,
          this.$t('headends.errorRebootingCam')
        )
      })
    },
    downloadDetailedReport: function () {
      this.$store.dispatch(LOADING_INCREASE)
      DevicesService.deviceReport(this.headend.id)
        .then(response => {
          this.$store.dispatch(LOADING_DECREASE)
          this.$store.dispatch(
            SNACKBAR_SUCCESS,
            this.$t('success.downloadingDetailedReport')
          )
          var link = document.createElement('a')
          var file = new Blob([JSON.stringify(response.data, null, 4)], { type: 'text/plain' })
          link.href = URL.createObjectURL(file)
          const date = new Date()
          link.download = this.headend.id + '-' + date.toISOString() + '.json'
          link.click()
        })
        .catch(error => {
          this.$store.dispatch(LOADING_DECREASE)
          if (error.response.status !== 401) {
            this.$store.dispatch(
              SNACKBAR_ERROR,
              this.$t('error.downloadingDetailedReport')
            )
          }
        })
    }
  }
}
</script>
