<template>
  <v-list>
    <v-list-item>
      <v-list-item-content class="caption version_list">
        <div v-show="false">
          {{ $t('phsVersion') }}: {{ version }}
        </div>
        <div v-show="false">
          {{ $t('phsBuild') }}: {{ build }}
        </div>
      </v-list-item-content>
    </v-list-item>
    <v-list-item v-if="!$isSky">
      <v-list-item-content class="caption">
        <a
          :href="apiDocLink"
          class="version_list"
        >
          {{ $t('apiDoc.linkTitle') }}
        </a>
      </v-list-item-content>
    </v-list-item>
  </v-list>
</template>
<script>
import Vue from 'vue'
import ServerApi from '@/services/api/Server.js'
export default {
  name: 'DrawerVersion',
  data () {
    return {
      version: this.$frontendVersion,
      build: this.$frontendBuildDate,
      apiDocLink: Vue.prototype.$isChorus ? '/sm/documentationAPI' : '/documentationAPI'
    }
  },
  created: function () {
    ServerApi.getDummy()
  }
}
</script>
<style scoped>
.version_list {
  color: gray;
}
</style>
