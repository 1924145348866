<template>
  <v-container fluid>
    <v-row v-if="headendIdIsValid">
      <!-- ACTIONS -->
      <HeadendViewBasic />
    </v-row>
    <div
      v-if="headendIdIsValid"
      class="mt-4"
    >
      <HeadendComponent
        :headend-id="headendId"
        :public-domain="headendPublicDomain"
        :client-id="clientId"
        :user-id="userId"
        :user-role="userRole"
      />
    </div>
  </v-container>
</template>
<script>
import { mapGetters } from 'vuex'
import HeadendViewBasic from '@/views/tsr/components/HeadendViewBasic'

export default {
  name: 'ViewHeadend',
  components: {
    HeadendViewBasic
  },
  data () {
    return {
      headendId: '',
      // headendPublicDomain: process.env.VUE_APP_PUBLIC_DOMAIN,
      headendPublicDomain: window.location.host,
      clientId: '',
      userId: '',
      userRole: ''
    }
  },
  computed: {
    ...mapGetters(['getAccountClientId', 'getAccountUserId', 'getAccountUserRole']),
    headendIdIsValid: function () {
      return this.headendId !== null && this.headendId !== ''
    }
  },
  created () {
    this.headendId = this.$route.params.deviceId
    this.clientId = this.getAccountClientId
    this.userId = this.getAccountUserId
    this.userRole = this.getAccountUserRole
  },
  mounted () {},
  methods: {}
}
</script>
