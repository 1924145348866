<template>
  <v-app>
    <TheAppBar
      :show-menu="showMenu"
      @clickOnTheMenuButton="toggleMenu"
    />

    <v-navigation-drawer
      v-if="!isANonAuthenticatedPage"
      v-model="showMenu"
      :right="$isSky ? true : $vuetify.breakpoint.mdAndDown"
      app
      clipped
      temporary
    >
      <TheMenu
        v-if="!$isSky"
        @return="toggleMenu"
        @logout="showMenu = false"
      >
        <template #default>
          <Version />
        </template>
      </TheMenu>
      <TheMenu
        v-if="$isSky"
        @return="toggleMenu"
        @logout="showMenu = false"
      >
        <template #append>
          <Version />
        </template>
      </TheMenu>
    </v-navigation-drawer>
    <v-main app>
      <SnackBar />
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
import Vue from 'vue'
import { mapGetters } from 'vuex'

import Version from '@/components/Version.vue'
import SnackBar from '@/components/common/SnackBar.vue'
import TheAppBar from '@/components/TheAppBar.vue'
import TheMenu from '@/components/TheMenu.vue'

export default Vue.extend({
  name: 'App',
  components: {
    Version,
    SnackBar,
    TheAppBar,
    TheMenu
  },
  data () {
    return {
      showMenu: false,
      loaderIsLoading: undefined,
      loaderAreItemsLoading: undefined
    }
  },
  computed: {
    ...mapGetters(['areItemsLoading']),
    isANonAuthenticatedPage: function () {
      return this.$route.path === '/login' || this.$route.path === '/forgotPassword' || this.$route.path === '/restorePassword' || this.$route.path === '/bypass'
    }
  },
  watch: {
    areItemsLoading: function (newValue) {
      if (newValue) {
        this.loaderAreItemsLoading = this.$loading.show({
          // Optional parameters
          canCancel: false,
          isFullPage: true,
          loader: 'spinner',
          color: '#007bff',
          backgroundColor: '#000000',
          height: 128,
          width: 128,
          opacity: 0.8
        })
      } else {
        this.loaderAreItemsLoading.hide()
      }
    }
  },
  created: function () {
    if (this.$store.getters['customization/isSky']) {
      document.title = this.$t('webTitle.sky')
    } else if (this.$store.getters['customization/isTabcorp']) {
      document.title = this.$t('webTitle.tabcorp')
    } else {
      // IKUSI
      document.title = this.$t('webTitle.ikusi')
    }
    this.$vuetify.theme.themes.light.primary = this.$store.getters[
      'customization/primaryColor'
    ]
  },
  mounted: function () {},
  methods: {
    toggleMenu: function () {
      this.showMenu = !this.showMenu
    }
  }
})
</script>
<style>
/* Pijadas de Joaquin
  https://vuetifyjs.com/en/introduction/frequently-asked-questions#the-scrollbar-is-showing-even-though-my-content-is-not-overflowing-vertically
  */
html {
  overflow-y: auto;
}
/* Se quita el subrayado de los links */
a {
  text-decoration: none;
}
</style>
<style scoped>
.after-icon {
  margin: auto;
  color: #fff;
}
</style>
