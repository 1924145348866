<template>
  <div />
</template>

<script>
import { AUTH_BYPASS } from '@/store/actions/authentication'

export default {
  name: 'Bypass',
  components: {},
  data () {
    return {
    }
  },
  created: function () {
    const queryString = window.location.search
    const urlParams = new URLSearchParams(queryString)
    if (urlParams.has('item')) {
      const bkAccessToken = localStorage.getItem('access_token')
      localStorage.setItem('bk_access_token', bkAccessToken)

      const bkExpires = localStorage.getItem('expires')
      localStorage.setItem('bk_expires', bkExpires)

      const bkRefreshToken = localStorage.getItem('refresh_token')
      localStorage.setItem('bk_refresh_token', bkRefreshToken)

      const bkScope = localStorage.getItem('scope')
      localStorage.setItem('bk_scope', bkScope)

      let triaxByPass = urlParams.get('item')
      triaxByPass = window.atob(triaxByPass)
      const data = triaxByPass.split('_v_')
      console.dir(data)
      this.$store
        .dispatch(AUTH_BYPASS, {
          access_token: data[1],
          expires_in: data[2],
          refresh_token: data[3],
          scope: data[4]
        })
        .then(response => {
          const expires = new Date(data[2])
          document.cookie = 'triaxBypass=vAz; expires=' + expires + '; path=/;'
          window.location.href = window.location.hostname + '/headends'
        })
        .catch()
    }
  },
  methods: {
  }
}
</script>
